import initialState from './state'

export const RESET = (state) => {
  const s = initialState()
  Object.keys(s).forEach((key) => (state[key] = s[key]))
}

export const SET_ALIVE = (state, payload) => (state.alive = payload.alive)

export const SET_IDENTITY = (state, payload) =>
  (state.identity = payload.identity)

export const ENQUIRY_SENT = (state) => (state.enquirySent = true)
