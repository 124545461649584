//import AxiosInterceptor from '@/shared/AxiosInterceptor'
import AxiosAuthenticated from '@/shared/AxiosAuthenticated'

export const getTimeZoneOptions = ({ commit }) => {
  return AxiosAuthenticated.get('account/timezone-list')
    .then((response) =>
      commit('SET_TIMEZONE_OPTIONS', { timeZoneOptions: response.data })
    )
    .catch(() => Promise.reject())
}
