<template>
  <button
    @click="action"
    class="text-sm mb-2 py-1 w-full rounded-md border-2 font-montserrat font-medium hover:ring-2 hover:ring-offset-0"
    :class="{
      'bg-green text-black border-green-100 hover:ring-green-100': primary,
      'bg-black-200 text-grey-100 border border-silver border-opacity-50 hover:ring-silver hover:ring-opacity-50':
        inverted,
      'bg-black-200 text-silver border-grey-200 border-opacity-20 hover:ring-grey-200 hover:ring-opacity-40':
        !primary && !inverted,
      'pointer-events-none opacity-60': disabled
    }"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    action: {
      type: Function,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    primary: {
      type: Boolean,
      required: false,
      default: true
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
