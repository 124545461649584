import initialState from './state'

export const RESET = (state) => {
  const s = initialState()
  Object.keys(s).forEach((key) => (state[key] = s[key]))
}

export const SET_INPLAY_GAMES = (state, payload) =>
  (state.inplayGames = payload.inplayGames)

export const SET_PREVIOUS_RESULTS = (state, payload) => {
  state.previousResults = payload.previousResults
}

export const EXPAND_LEAGUE = (state, payload) => {
  if (state.leaguesExpanded.every((x) => x !== payload.leagueId))
    state.leaguesExpanded.push(payload.leagueId)
}

export const COLLAPSE_LEAGUE = (state, payload) => {
  state.leaguesExpanded = state.leaguesExpanded.filter(
    (x) => x !== payload.leagueId
  )
}

export const EXPAND_ALL_LEAGUES = (state) =>
  (state.leaguesExpanded = state.inplayGames.map((x) => x.currentLeague.id))

export const COLLAPSE_ALL_LEAGUES = (state) => (state.leaguesExpanded = [])

export const EXPAND_ODDS = (state, payload) => {
  if (state.oddsExpanded.every((x) => x !== payload.fixtureId))
    state.oddsExpanded.push(payload.fixtureId)
}

export const COLLAPSE_ODDS = (state, payload) =>
  (state.oddsExpanded = state.oddsExpanded.filter(
    (x) => x !== payload.fixtureId
  ))

export const ADD_FAVOURITE = (state, payload) => {
  if (state.favourites.every((x) => x !== payload.fixtureId))
    state.favourites.push(payload.fixtureId)
}

export const REMOVE_FAVOURITE = (state, payload) =>
  (state.favourites = state.favourites.filter((x) => x !== payload.fixtureId))

export const SET_FILTERS = (state, payload) => {
  state.filters.allGames = false
  state.filters.firstHalf = false
  state.filters.secondHalf = false
  state.filters.halfTime = false
  state.filters.drawScore = false
  state.filters.underdogWinning = false
  state.filters.favouriteWinning = false
  state.filters.myFavourites = false

  state.filters[payload.filter] = true
}

export const SET_SEARCH_FILTER = (state, payload) =>
  (state.filters.search = payload.search)

export const SET_NOTIFICATIONS = (state, payload) => {
  state.inplayNotifications = payload.notifications
  state.readInplayNotifications = state.readInplayNotifications.filter((x) =>
    state.inplayNotifications.map((y) => y.id).includes(x)
  )
}

export const REMOVE_ALL_NOTIFICATIONS = (state) =>
  (state.inplayNotifications = [])

export const REMOVE_SINGLE_NOTIFICATION = (state, payload) => {
  state.inplayNotifications = state.inplayNotifications.filter(
    (notification) => {
      return notification.id !== payload.id
    }
  )
}

export const UPDATE_READ_NOTIFICATIONS = (state) => {
  state.readInplayNotifications = state.inplayNotifications.map((x) => x.id)
}
