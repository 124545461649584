<template>
  <div class="flex flex-col items-start w-full mb-2 relative">
    <div class="flex items-center justify-between w-full h-5">
      <span class="text-sm">{{ label }}</span>
      <span v-show="invalid && !editing" class="text-sm text-red">{{
        invalidMessage
      }}</span>
      <span v-show="successMessage.length > 0" class="text-sm text-green">{{
        successMessage
      }}</span>
      <span v-show="failMessage.length > 0" class="text-sm text-red">{{
        failMessage
      }}</span>
    </div>
    <input
      readonly
      ref="selector"
      :value="selected"
      class="z-10 cursor-pointer bg-transparent py-1 pl-2 pr-8 w-full rounded-md border border-silver border-opacity-50 outline-none caret-black font-light text-sm text-chalk hover:ring-2 hover:ring-green-100 hover:ring-offset-0"
      :class="{ 'rounded-b-none border-b-transparent': dropdownOpen }"
    />
    <img
      src="@/assets/images/caret-down.png"
      class="w-6 absolute top-6 right-0"
      :class="{ 'rotate-180': dropdownOpen }"
    />
    <div
      v-if="dropdownOpen"
      class="cursor-pointer absolute top-full max-h-32 w-full overflow-y-scroll bg-black rounded-b-md border border-silver border-opacity-50 z-20"
    >
      <div
        v-for="option in model.options"
        :key="option.label == null ? option : option.label"
        class="pl-2 py-1 text-sm dropdown-item hover:bg-green-200"
        :class="{
          'bg-green-200':
            (option.label == null ? option : option.label) === selected
        }"
        @click="
          $emit('valueChange', option.value == null ? option : option.value)
        "
      >
        {{ option.label == null ? option : option.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
export default {
  name: 'BaseDropdown',
  props: {
    model: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    invalidMessage: {
      type: String,
      required: false
    },
    successMessage: {
      type: String,
      required: false,
      default: ''
    },
    failMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const selector = ref(null)

    const dropdownOpen = ref(false)

    const cancelInput = (event) => {
      event.preventDefault()
    }

    const openDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value
      document.addEventListener('click', handleOffClick)
    }

    const handleOffClick = (event) => {
      if (event.target != selector.value) dropdownOpen.value = false
    }

    onMounted(() => {
      selector.value.addEventListener('click', openDropdown)
      selector.value.addEventListener('input', cancelInput)
    })

    onBeforeUnmount(() => {
      selector.value.removeEventListener('click', openDropdown)
      selector.value.removeEventListener('input', cancelInput)
      document.removeEventListener('click', handleOffClick)
    })

    const selected = computed(() => {
      var selected = props.model.options.find((x) => x === props.model.value)
      if (selected != null) return selected

      selected = props.model.options.find((x) => x.value === props.model.value)

      if (selected != null) return selected.label
      else return props.model.value
    })
    return {
      dropdownOpen,
      selector,
      selected,
      newModel: props.model
    }
  }
}
</script>
