<template>
  <div v-if="alive && loadDelay == false" class="min-h-full h-full bg-black">
    <TheNavBar v-if="showNav" />
    <router-view class="min-h-full h-full bg-black" :class="{ '': showNav }" />
  </div>
  <div v-else class="min-h-full h-full bg-black">
    <TheStatusDisplay />
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import TheNavBar from '@/components/TheNavBar.vue'
import TheStatusDisplay from '@/components/TheStatusDisplay.vue'

export default {
  components: {
    TheNavBar,
    TheStatusDisplay
  },
  setup() {
    const route = useRoute()
    const store = useStore()

    const showNav = computed(() => route.meta.requiresAuth)
    const alive = computed(() => store.state.alive)

    const loadDelay = ref(true)

    setTimeout(() => {
      loadDelay.value = false
    }, 2000)

    var alertsChronometer = {
      id: null,
      interval: 15000
    }

    onMounted(() => {
      var viewport = document.querySelector('meta[name=viewport]')
      viewport.setAttribute(
        'content',
        viewport.content + ', height=' + window.innerHeight
      )

      alertsChronometer.id = setInterval(() => {
        if (alive.value && showNav.value) {
          store.dispatch('inplay/getInplayAlerts')
        }
      }, alertsChronometer.interval)
    })

    onUnmounted(() => clearInterval(alertsChronometer.id))

    return {
      showNav,
      alive,
      loadDelay
    }
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(226, 224, 224);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9fa39fc9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b1b6b1;
}

// SCROLLABLE

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .scrolling-card {
    flex: 0 0 auto;
  }

  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

// FLASH

.flash {
  -webkit-animation-name: flash;
  -webkit-animation-duration: 0.8s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

@-webkit-keyframes flash {
  from {
    color: rgb(206, 206, 206);
    font-weight: bold;
  }

  to {
    color: #b1f2cc;
    font-weight: bold;
  }
}
</style>
