<template>
  <div class="flex flex-col items-start w-full mb-2">
    <div class="flex items-center justify-between w-full h-5">
      <span class="text-sm">{{ label }}</span>
      <span v-show="invalid && !editing" class="text-sm text-red">{{
        invalidMessage
      }}</span>
      <span v-show="successMessage.length > 0" class="text-sm text-green">{{
        successMessage
      }}</span>
      <span v-show="failMessage.length > 0" class="text-sm text-red">{{
        failMessage
      }}</span>
    </div>
    <input
      ref="input"
      v-model="value"
      :type="setType"
      :maxlength="setMaxLength"
      @keyup.enter="submit"
      @focus="focus"
      @blur="blur"
      class="bg-transparent py-1 pl-2 w-full rounded-md border border-silver border-opacity-50 outline-none text-sm font-light text-chalk hover:ring-2 hover:ring-green-100 hover:ring-offset-0"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  name: 'BaseTextbox',
  emits: ['valueChange', 'submit', 'blur'],
  props: {
    model: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    invalidMessage: {
      type: String,
      required: false
    },
    setType: {
      type: String,
      required: false,
      default: 'text'
    },
    setMaxLength: {
      type: Number,
      required: false,
      default: 524288
    },
    successMessage: {
      type: String,
      required: false,
      default: ''
    },
    failMessage: {
      type: String,
      required: false,
      default: ''
    },
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    onBlur: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const input = ref(null)
    const editing = ref(false)
    const numberOnly = ref(props.model === '0')

    const submit = () => {
      input.value.blur()
      props.onSubmit()
    }

    const value = computed({
      get() {
        return props.model
      },
      set(value) {
        emit('valueChange', value)
      }
    })

    const focus = () => {
      editing.value = true
      if (props.model === '0') emit('valueChange', '')
    }

    const blur = () => {
      editing.value = false
      if (props.model === '' && numberOnly.value) emit('valueChange', '0')
      props.onBlur()
    }

    return {
      input,
      editing,
      submit,
      value,
      focus,
      blur
    }
  }
}
</script>
