import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Guest',
    component: () => import('../views/GuestView.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicyView.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/in-play',
    name: 'In Play',
    component: () => import('../views/InPlayView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/AccountView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: () => import('../views/AlertsView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUsView.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  const unAuthedRedirect = 'Guest'
  const authenticatedPage = to.matched.some(
    (record) => record.meta.requiresAuth
  )

  return store
    .dispatch('checkAlive')
    .then(() => store.dispatch('identify'))
    .then(() => store.dispatch('account/getAccountInfo', null, { root: true }))
    .then(() => {
      if (to.name === unAuthedRedirect)
        return { name: from.meta.requiresAuth ? from.name : 'In Play' }
    })
    .catch(() => {
      if (to.name !== unAuthedRedirect && authenticatedPage)
        return { name: unAuthedRedirect }
    })
})

export default router
