/* eslint-disable quotes */
const initialState = () => {
  return {
    customAlerts: {
      alerts: [],
      filters: [],
      filterGroups: [],
      checkedRegions: []
    },
    customAlertFilters: [],
    alertPanel: false,
    customAlertOptions: {
      categories: {
        specialist: 'Specialist',
        matchEvent: 'Match Events',
        matchStats: 'Match Statistics',
        odds: 'Odds',
        other: 'Other'
      },
      conditions: {
        matchEvents: {
          any: {
            newGoal: 'New Goal',
            newShot: 'New Shot',
            newShotOn: 'New Shot On Target',
            newShotOff: 'New Shot Off Target',
            newShotInBox: 'New Shot In Box',
            newCorner: 'New Corner',
            newAttack: 'New Attack',
            newDangerousAttack: 'New Dangerous Attack',
            newYellowCard: 'New Yellow Card',
            newRedCard: 'New Red Card',
            newSave: 'New Save'
          }
        },
        matchStats: {
          total: {
            goals: 'Goals',
            allShots: 'All Shots',
            shotsOn: 'Shots On Target',
            shotsOff: 'Shots Off Target',
            shotsInBox: 'Shots In Box',
            corners: 'Corners',
            attacks: 'Attacks',
            dangerousAttacks: 'Dangerous Attacks',
            yellowCard: 'Yellow Cards',
            redCard: 'Red Cards',
            saves: 'Saves'
          },
          goalDiff: 'Goal Difference',
          possession: 'Possession'
        },
        odds: {
          misc: {
            homeWin: 'Home Win',
            awayWin: 'Away Win',
            draw: 'Draw',
            favourite: 'Favourite Win',
            underdog: 'Underdog Win',
            btsYes: 'Both Teams Score (Yes)',
            btsNo: 'Both Teams Score (No)',
            nextGoalMarketUnder: 'Next Goal Market (Under)',
            nextGoalMarketOver: 'Next Goal Market (Over)'
          },
          undersOvers: {
            min: 0.5,
            max: 15.5,
            under: 'Under',
            over: 'Over',
            firstHalfUnder: '1st Half Under',
            firstHalfOver: '1st Half Over'
          }
        },
        other: {
          minutesPlayed: 'Minutes Played',
          injuryTime: 'Injury Time Played',
          declaredAddedTime: 'Declared Added Time',
          leaguePosHome: 'League Position (Home)',
          leaguePosAway: 'League Position (Away)',
          leaguePosWinning: 'League Position (Winning)',
          leaguePosLosing: 'League Position (Losing)'

          //league: 'Specific League',
          //region: 'Region'
        },
        specialist: {
          underdogWinning: 'Underdog Winning',
          favouriteWinning: 'Favourite Winning',
          hotGame: 'Hot Game',
          coldGame: 'Cold Game',
          oddsSuspended: 'Odds Suspended',
          oddsResumed: 'Odds Resumed',
          bothTeamsToScore: 'Both Teams Score',
          teamWinningHome: 'Team Winning (Home)',
          teamWinningAway: 'Team Winning (Away)'
        }
      },
      operators: {
        andAll: 'All',
        anyOf: 'Any Of',
        equalTo: 'Equal To',
        notEqualTo: 'Not Equal To',
        lessThan: 'Less Than',
        lessThanOrEqualTo: 'Less Or Equal To',
        greaterThan: 'Greater Than',
        greaterThanOrEqualTo: 'Greater Or Equal To',
        total: 'Total',
        true: 'True',
        false: 'False',
        na: 'N/A'
      },
      times: {
        preMatch: 'Pre-Match',
        firstHalf: 'First Half',
        secondHalf: 'Second Half',
        entireGame: 'Entire Game',
        nMinutes: 'Last (n) Minutes',
        injuryTime: 'Injury Time',
        inplay: 'In-Play'
      }
    },
    leagueFilters: []
    // checkedRegions: [
    //   'World',
    //   'World - WC Qualification South America',
    //   'World - WC Qualification Asia',
    //   'World - Friendly International',
    //   'World - Friendly International U21',
    //   'World - Armenia',
    //   'World - Armenia - Premier League',
    //   'World - Australia',
    //   'World - Australia - A-League',
    //   'World - Azerbaijan',
    //   'World - Azerbaijan - Premier League',
    //   'World - Georgia',
    //   'World - Georgia - Crystalbet Erovnuli Liga',
    //   'World - Russia',
    //   'World - Russia - Premier League',
    //   'World - Russia - FNL',
    //   'World - Russia - Pfl',
    //   'World - New Zealand',
    //   'World - New Zealand - Premiership',
    //   'World - Qatar',
    //   'World - Qatar - Premier League',
    //   'World - Saudi Arabia',
    //   'World - Saudi Arabia - Pro League',
    //   'World - Saudi Arabia - Division 1',
    //   'World - Iran',
    //   'World - Iran - Persian Gulf Pro League',
    //   'World - Israel',
    //   // eslint-disable-next-line quotes
    //   "World - Israel - Ligat ha'Al",
    //   'World - Jordan',
    //   'World - Jordan - Premier League',
    //   'World - Kazakhstan',
    //   'World - Kazakhstan - Premier League',
    //   'World - Kuwait',
    //   'World - Kuwait - Premier League',
    //   'World - Syria',
    //   'World - Syria - Premier League',
    //   'World - Turkey',
    //   'World - Turkey - Super Lig',
    //   'World - Turkey - 1. Lig',
    //   'World - United Arab Emirates',
    //   'World - United Arab Emirates - Uae League',
    //   'World - USA',
    //   'World - USA - Major League Soccer',
    //   'Europe',
    //   'Europe - Albania',
    //   'Europe - Albania - 1st Division',
    //   'Europe - Albania - 2nd Division',
    //   'Europe - Albania - Albanian Cup',
    //   'Europe - Austria',
    //   'Europe - Austria - Admiral Bundesliga',
    //   'Europe - Austria - Tipico Bundesliga',
    //   'Europe - Austria - Erste Liga',
    //   'Europe - Belarus',
    //   'Europe - Belarus - Vysshaya Liga',
    //   'Europe - Belarus - Pershaya Liga',
    //   'Europe - Belgium',
    //   'Europe - Belgium - Pro League',
    //   'Europe - Belgium - First Division B',
    //   'Europe - Bosnia and Herzegovina',
    //   'Europe - Bosnia and Herzegovina - Premier Liga',
    //   'Europe - Bulgaria',
    //   'Europe - Bulgaria - Parva Liga',
    //   'Europe - Croatia',
    //   'Europe - Croatia - 1. HNL',
    //   'Europe - Croatia - 2. HNL',
    //   'Europe - Croatia - 3. HNL - East',
    //   'Europe - Croatia - 3. HNL - South',
    //   'Europe - Croatia - 3. HNL - West',
    //   'Europe - Czech Republic',
    //   'Europe - Czech Republic - Fortuna Liga',
    //   'Europe - Denmark',
    //   'Europe - Denmark - Superliga',
    //   'Europe - Denmark - First Division',
    //   'Europe - Denmark - Landspokal Cup',
    //   'Europe - Denmark - 2. Division Group 1',
    //   'Europe - Denmark - 2. Division Group 2',
    //   'Europe - Denmark - Denmark Series Group 1',
    //   'Europe - Denmark - Denmark Series Group 2',
    //   'Europe - Denmark - Denmark Series Group 3',
    //   'Europe - Denmark - Denmark Series Group 4',
    //   'Europe - England',
    //   'Europe - England - Premier League',
    //   'Europe - England - Championship',
    //   'Europe - England - League One',
    //   'Europe - England - League Two',
    //   'Europe - England - National League',
    //   'Europe - England - Premier League U21',
    //   'Europe - England - Premier League 2 Division One',
    //   'Europe - England - Premier League 2 Divison Two',
    //   'Europe - England - Community Shield',
    //   'Europe - England - FA Cup',
    //   'Europe - England - Carabao Cup',
    //   'Europe - England - Fa Trophy',
    //   'Europe - Estonia',
    //   'Europe - Estonia - Meistriliiga',
    //   'Europe - Estonia - Esiliiga A',
    //   'Europe - Finland',
    //   'Europe - Finland - Veikkausliiga',
    //   'Europe - Finland - Ykkonen',
    //   'Europe - Finland - Kakkonen',
    //   'Europe - Finland - Kolmonen',
    //   'Europe - France',
    //   'Europe - France - Ligue 1',
    //   'Europe - France - Ligue 2',
    //   'Europe - France - National',
    //   'Europe - Germany',
    //   'Europe - Germany - Bundesliga',
    //   'Europe - Germany - 2. Bundesliga',
    //   'Europe - Germany - 3. Liga',
    //   'Europe - Germany - Regionalliga: Bayern',
    //   'Europe - Germany - Regionalliga: Nordost',
    //   'Europe - Germany - Regionalliga: Südwest',
    //   'Europe - Germany - Regionalliga: West',
    //   'Europe - Greece',
    //   'Europe - Greece - Super League',
    //   'Europe - Hungary',
    //   'Europe - Hungary - OTP Bank Liga',
    //   'Europe - Hungary - Merkantil Bank Liga',
    //   'Europe - Iceland',
    //   'Europe - Iceland - Pepsideild',
    //   'Europe - Iceland - Inkasso-Deildin',
    //   'Europe - Iceland - 2. Deild',
    //   'Europe - Iceland - Iceland Cup',
    //   'Europe - Iceland - League Cup',
    //   'Europe - Iceland - 4. Deild',
    //   'Europe - Iceland - 3. Deild',
    //   'Europe - Italy',
    //   'Europe - Italy - Serie A',
    //   'Europe - Italy - Serie B',
    //   'Europe - Italy - Coppa Italia',
    //   'Europe - Latvia',
    //   'Europe - Latvia - Virsliga',
    //   'Europe - Malta',
    //   'Europe - Malta - Premier League',
    //   'Europe - Moldova',
    //   'Europe - Moldova - National Division',
    //   'Europe - Netherlands',
    //   'Europe - Netherlands - Eredivisie',
    //   'Europe - Netherlands - Eerste Divisie',
    //   'Europe - Northern Ireland',
    //   'Europe - Northern Ireland - Premiership',
    //   'Europe - Norway',
    //   'Europe - Norway - Eliteserien',
    //   'Europe - Norway - Obos-Ligaen',
    //   'Europe - Norway - NM Cup',
    //   'Europe - Norway - 2. Division',
    //   'Europe - Norway - Super Cup',
    //   'Europe - Norway - 3. Division',
    //   'Europe - Poland',
    //   'Europe - Poland - Ekstraklasa',
    //   'Europe - Poland - 1. Liga',
    //   'Europe - Poland - 2. Liga East',
    //   'Europe - Portugal',
    //   'Europe - Portugal - Primeira Liga',
    //   'Europe - Portugal - Segunda Liga',
    //   'Europe - Portugal - Taça De Portugal',
    //   'Europe - Romania',
    //   'Europe - Romania - Liga 1',
    //   'Europe - Romania - Liga 2',
    //   'Europe - Scotland',
    //   'Europe - Scotland - Premiership',
    //   'Europe - Scotland - Championship',
    //   'Europe - Scotland - League One',
    //   'Europe - Scotland - League Two',
    //   'Europe - Serbia',
    //   'Europe - Serbia - Super Liga',
    //   'Europe - Serbia - Prva Liga',
    //   'Europe - Slovakia',
    //   'Europe - Slovakia - Fortuna Liga',
    //   'Europe - Slovenia',
    //   'Europe - Slovenia - 1. SNL',
    //   'Europe - Spain',
    //   'Europe - Spain - La Liga',
    //   'Europe - Spain - La Liga 2',
    //   'Europe - Spain - Copa Del Rey',
    //   'Europe - Spain - Segunda B - Group 1',
    //   'Europe - Spain - Segunda B - Group 2',
    //   'Europe - Spain - Segunda B - Group 3',
    //   'Europe - Spain - Segunda B - Group 4',
    //   'Europe - Spain - Segunda B - Playoffs',
    //   'Europe - Spain - Tercera - Group 1',
    //   'Europe - Spain - Tercera - Group 2',
    //   'Europe - Spain - Tercera - Group 3',
    //   'Europe - Spain - Super Cup',
    //   'Europe - Sweden',
    //   'Europe - Sweden - Allsvenskan',
    //   'Europe - Sweden - Superettan',
    //   'Europe - Sweden - Ettan: North',
    //   'Europe - Sweden - Ettan: South',
    //   'Europe - Switzerland',
    //   'Europe - Switzerland - Super League',
    //   'Europe - Switzerland - Challenge League',
    //   'Europe - Ukraine',
    //   'Europe - Ukraine - Premier League',
    //   'Europe - Wales',
    //   'Europe - Wales - Premier League',
    //   'Europe - Champions League',
    //   'Europe - Europa League',
    //   'Europe - Europa Conference League',
    //   'Europe - WC Qualification Europe',
    //   'Europe - European Championship',
    //   'Europe - Euro U21',
    //   'Europe - Baltic Cup',
    //   'Europe - UEFA Nations League',
    //   'Europe - U21 Championship Qualification',
    //   'South America',
    //   'South America - Copa America',
    //   'South America - Copa Libertadores',
    //   'South America - Recopa Sudamericana',
    //   'South America - Argentina',
    //   'South America - Argentina - Superliga',
    //   'South America - Argentina - Copa Argentina',
    //   'South America - Argentina - Primera B Nacional',
    //   'South America - Argentina - Primera C',
    //   'South America - Argentina - Primera D Metropolitana',
    //   'South America - Argentina - Primera B Metropolitana',
    //   'South America - Bolivia',
    //   'South America - Bolivia - Liga De Futbol Prof',
    //   'South America - Brazil',
    //   'South America - Brazil - Serie A',
    //   'South America - Brazil - Serie B',
    //   'South America - Brazil - Copa do Brasil',
    //   'South America - Brazil - Serie C',
    //   'South America - Brazil - Serie D',
    //   'South America - Brazil - Paulista A1',
    //   'South America - Brazil - Paulista A2',
    //   'South America - Brazil - Paulista A3',
    //   'South America - Brazil - Brasileiro U20',
    //   'South America - Brazil - Copa Do Brasil U20',
    //   'South America - Chile',
    //   'South America - Chile - Primera Division',
    //   'South America - Chile - Segunda División',
    //   'South America - Colombia',
    //   'South America - Colombia - Liga BetPlay',
    //   'South America - Colombia - Torneo Betplay',
    //   'South America - Costa Rica',
    //   'South America - Costa Rica - Primera Division',
    //   'South America - Ecuador',
    //   'South America - Ecuador - Liga Pro',
    //   'South America - Ecuador - Primera B',
    //   'South America - El Salvador',
    //   'South America - El Salvador - Primera Division',
    //   'South America - Honduras',
    //   'South America - Honduras - Liga Nacional',
    //   'South America - Mexico',
    //   'South America - Mexico - Liga MX',
    //   'South America - Mexico - Liga de Expansión MX',
    //   'South America - Nicaragua',
    //   'South America - Nicaragua - Primera Division',
    //   'South America - Panama',
    //   'South America - Panama - Lpf',
    //   'South America - Paraguay',
    //   'South America - Paraguay - Division 1',
    //   'South America - Peru',
    //   'South America - Peru - Primera Division',
    //   'South America - Peru - Segunda Division',
    //   'South America - Uruguay',
    //   'South America - Uruguay - Primera Division',
    //   'South America - Uruguay - Segunda Division',
    //   'South America - Venezuela',
    //   'South America - Venezuela - Primera Division',
    //   'Africa',
    //   'Africa - Africa Cup of Nations',
    //   'Africa - Africa Cup of Nations Qualifications',
    //   'Africa - WC Qualification Africa',
    //   'Africa - Algeria',
    //   'Africa - Algeria - Ligue 1',
    //   'Africa - Algeria - Ligue 2',
    //   'Africa - Algeria - Algeria Cup',
    //   'Africa - Egypt',
    //   'Africa - Egypt - Premier League',
    //   'Africa - Egypt - Egypt Cup',
    //   'Africa - Egypt - Second League: Group A',
    //   'Africa - Egypt - Second League: Group B',
    //   'Africa - Egypt - Second League: Group C',
    //   'Africa - Gambia',
    //   'Africa - Gambia - GFA League',
    //   'Africa - Ghana',
    //   'Africa - Ghana - Premier League',
    //   'Africa - Kenya',
    //   'Africa - Kenya - Premier League',
    //   'Africa - Kenya - Super League',
    //   'Africa - Mali',
    //   'Africa - Mali - Premiere Division',
    //   'Africa - Mauritius',
    //   'Africa - Mauritius - Mauritian League',
    //   'Africa - Morocco',
    //   'Africa - Morocco - Botola Pro',
    //   'Africa - Morocco - Botola 2',
    //   'Africa - Rwanda',
    //   'Africa - Rwanda - National Soccer League',
    //   'Africa - South Africa',
    //   'Africa - South Africa - Premier League',
    //   'Africa - Tanzania',
    //   'Africa - Tanzania - Ligi kuu Bara',
    //   'Africa - Tunisia',
    //   'Africa - Tunisia - Ligue 1',
    //   'Asia',
    //   'Asia - Bangladesh',
    //   'Asia - Bangladesh - Premier League',
    //   'Asia - Bhutan',
    //   'Asia - Bhutan - Super League',
    //   'Asia - China PR',
    //   'Asia - China PR - Super League',
    //   'Asia - China PR - League One',
    //   'Asia - China PR - Yi League',
    //   'Asia - Hong Kong',
    //   'Asia - Hong Kong - Premier League',
    //   'Asia - India',
    //   'Asia - India - Indian Super League',
    //   'Asia - Japan',
    //   'Asia - Japan - J-League',
    //   'Asia - Japan - J2-League',
    //   'Asia - Japan - J3-League',
    //   'Asia - Japan - Emperor Cup',
    //   'Asia - Japan - Ybc Levain Cup',
    //   'Asia - Korea Republic',
    //   'Asia - Korea Republic - K-League 1',
    //   'Asia - Korea Republic - K-League 2',
    //   'Asia - Thailand',
    //   'Asia - Thailand - Thai Premier League',
    //   'Asia - Thailand - Thai League Two',
    //   'Asia - Thailand - Thai League 3',
    //   'Asia - Vietnam',
    //   'Asia - Vietnam - V-League'
    // ]
    // conditions: [
    //   { value: 'New Goal', category: 'Match Events' },
    //   { value: 'New Shot', category: 'Match Events' },
    //   { value: 'New Shot On Target', category: 'Match Events' },
    //   { value: 'New Shot Off Target', category: 'Match Events' },
    //   { value: 'New Shot In Box', category: 'Match Events' },
    //   { value: 'New Corner', category: 'Match Events' },
    //   { value: 'New Attack', category: 'Match Events' },
    //   { value: 'New Dangerous Attack', category: 'Match Events' },
    //   { value: 'New Yellow Card', category: 'Match Events' },
    //   { value: 'New Red Card', category: 'Match Events' },
    //   { value: 'New Save', category: 'Match Events' },

    //   { value: 'Goals', category: 'Match Statistics' },
    //   { value: 'All Shots', category: 'Match Statistics' },
    //   { value: 'Shots On Target', category: 'Match Statistics' },
    //   { value: 'Shots Off Target', category: 'Match Statistics' },
    //   { value: 'Shots In Box', category: 'Match Statistics' },
    //   { value: 'Corners', category: 'Match Statistics' },
    //   { value: 'Attacks', category: 'Match Statistics' },
    //   { value: 'Dangerous Attacks', category: 'Match Statistics' },
    //   { value: 'Yellow Cards', category: 'Match Statistics' },
    //   { value: 'Red Cards', category: 'Match Statistics' },
    //   { value: 'Saves', category: 'Match Statistics' },

    //   { value: 'Goal Difference', category: 'Match Statistics' },
    //   { value: 'Possession', category: 'Match Statistics' },

    //   { value: 'Home Win', category: 'Odds' },
    //   { value: 'Away Win', category: 'Odds' },
    //   { value: 'Draw', category: 'Odds' },
    //   { value: 'Favourite Win', category: 'Odds' },
    //   { value: 'Underdog Win', category: 'Odds' },
    //   { value: 'Both Teams Score (Yes)', category: 'Odds' },
    //   { value: 'Both Teams Score (No)', category: 'Odds' },
    //   { value: 'Next Goal Market (Under)', category: 'Odds' },
    //   { value: 'Next Goal Market (Over)', category: 'Odds' },

    //   { value: 'Minutes Played', category: 'Other' },
    //   { value: 'Injury Time Played', category: 'Other' },
    //   { value: 'Declared Added Time', category: 'Other' },
    //   { value: 'League Position (Home)', category: 'Other' },
    //   { value: 'League Position (Away)', category: 'Other' },
    //   { value: 'League Position (Winning)', category: 'Other' },
    //   { value: 'League Position (Losing)', category: 'Other' },

    //   { value: 'Underdog Winning', category: 'specialist' },
    //   { value: 'Favourite Winning', category: 'specialist' },
    //   { value: 'Hot Game', category: 'specialist' },
    //   { value: 'Cold Game', category: 'specialist' },
    //   { value: 'Odds Suspended', category: 'specialist' },
    //   { value: 'Odds Resumed', category: 'specialist' },
    //   { value: 'Both Teams Score', category: 'specialist' },
    //   { value: 'Team Winning (Home)', category: 'specialist' },
    //   { value: 'Team Winning (Away)', category: 'specialist' }
    // ]
  }
}

export default initialState
