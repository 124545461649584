import initialState from './state'
import arrayIndex from '@/shared/ArrayIndex.js'

export const RESET = (state) => {
  const s = initialState()
  Object.keys(s).forEach((key) => (state[key] = s[key]))
}

export const PUSH_CUSTOM_ALERT = (state, payload) => {
  let alert = payload.alert

  if (alert.regionTree == null) alert.regionTree = []
  state.customAlerts.alerts.push(payload.alert)
}

export const UPDATE_CUSTOM_ALERT = (state, payload) => {
  var index = arrayIndex(state.customAlerts.alerts, 'id', payload.id)

  state.customAlerts.alerts[index].name = payload.name
  state.customAlerts.alerts[index].groupOperator = payload.groupOperator
}

export const SET_CUSTOM_ALERTS = (state, payload) => {
  let alerts = payload.alerts

  alerts.forEach((x) => {
    if (x.regionTree == null) x.regionTree = []
  })

  state.customAlerts.alerts = alerts
  state.customAlerts.filters = payload.filters
  state.customAlerts.filterGroups = payload.filterGroups
}

export const REMOVE_CUSTOM_ALERT = (state, payload) => {
  state.customAlerts.alerts = state.customAlerts.alerts.filter((alert) => {
    return alert.id !== payload.alertId
  })
}

export const SET_ALERT_ACTIVE_STATUS = (state, payload) => {
  var index = arrayIndex(state.customAlerts.alerts, 'id', payload.alertId)
  state.customAlerts.alerts[index].active = payload.active
}

export const PUSH_FILTER = (state, payload) => {
  state.customAlerts.filters.push(payload.filter)
}

export const REMOVE_FILTER = (state, payload) => {
  state.customAlerts.filters = state.customAlerts.filters.filter((filter) => {
    return filter.id !== payload.id
  })
}

export const PUSH_CUSTOM_ALERT_GROUP = (state, payload) => {
  state.customAlerts.filterGroups.push({
    id: payload.id,
    alertId: payload.alertId,
    parentGroupId: payload.parentGroupId,
    name: payload.name,
    groupOperator: payload.groupOperator,
    sumOperator: payload.sumOperator,
    sumValue: payload.sumValue
  })
}

export const REMOVE_CUSTOM_ALERT_GROUP = (state, payload) => {
  state.customAlerts.filterGroups = state.customAlerts.filterGroups.filter(
    (filterGroup) => {
      return filterGroup.id !== payload.id
    }
  )
}

export const UPDATE_CUSTOM_ALERT_GROUP = (state, payload) => {
  var index = arrayIndex(state.customAlerts.filterGroups, 'id', payload.id)

  state.customAlerts.filterGroups[index].name = payload.name
  state.customAlerts.filterGroups[index].groupOperator = payload.groupOperator
  state.customAlerts.filterGroups[index].sumOperator = payload.sumOperator
  state.customAlerts.filterGroups[index].sumValue = payload.sumValue
}

export const TOGGLE_ALERT_PANEL = (state) => {
  state.alertPanel = !state.alertPanel
}

export const UPDATE_ALERT_REGION_TREE = (state, payload) => {
  let alert = state.customAlerts.alerts.find((x) => x.id === payload.alertId)

  alert.regionTree = payload.checkedRegions
}

export const SET_ALERT_LEAGUE_FILTERS = (state, payload) => {
  state.leagueFilters = payload.leagueFilters
}
