import axios from 'axios'
import router from '@/router'
import store from '@/store'

const axiosAuthenticated = axios.create({})
const join = require('url-join')

axiosAuthenticated.interceptors.request.use((config) => {
  var isAbsoluteURLRegex = /^(?:\w+:)\/\//

  if (!isAbsoluteURLRegex.test(config.url))
    config.url = join(process.env.VUE_APP_API_BASE, config.url)

  config.headers.common['Authorization'] = `bearer ${store.state.identity}`
  return config
})

axiosAuthenticated.interceptors.response.use(
  async (response) => {
    if (response && response.data) return response
    return Promise.reject()
  },
  async (error) => {
    const originalRequest = error.config
    const errorCode = error.response ? error.response.status : null

    if (errorCode !== 401) {
      await store.dispatch('checkAlive')
      return Promise.reject(error)
    }

    try {
      await store.dispatch('identify')
      originalRequest.headers[
        'Authorization'
      ] = `bearer ${store.state.identity}`
      return axios.request(originalRequest)
    } catch (error) {
      router.push({ name: 'Guest' })
      return Promise.reject(error)
    }
  }
)

export default axiosAuthenticated
