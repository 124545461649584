import state from './state'
import * as actions from './actions'
import * as mutations from './mutations'

import account from './modules/accountModule'
import alert from './modules/alertModule'
import inplay from './modules/inplayModule'
import misc from './modules/miscModule'

import { createStore } from 'vuex'

import VuexPersistence from 'vuex-persist'
import Crypto from 'crypto-js'
import Cookie from 'js-cookie'
import generateUUID from '@/shared/GuidGenerator.js'

const localCookieName = 'localSettings'
const localStorageKey = 'Settings'
const localStorage = window.localStorage

const sessionCookieName = 'sessionSettings'
const sessionStorageKey = 'Settings'
const sessionStorage = window.sessionStorage

// Get the encryption token from cookie or generate a new one.
const localEncryptionToken = Cookie.get(localCookieName) || generateUUID()
const sessionEncryptionToken = Cookie.get(sessionCookieName) || generateUUID()

// Store the encryption token in a secure cookie.
Cookie.set(localCookieName, localEncryptionToken, {
  secure: true,
  expires: 180
})

Cookie.set(sessionCookieName, sessionEncryptionToken, { secure: true })

const getItem = (storage, token, key) => {
  const store = storage.getItem(key)

  if (store) {
    try {
      const bytes = Crypto.AES.decrypt(store, token)
      return JSON.parse(bytes.toString(Crypto.enc.Utf8))
    } catch (e) {
      storage.removeItem(key)
    }
  }

  return null
}

const setItem = (storage, token, key, value) => {
  const store = Crypto.AES.encrypt(value, token).toString()
  return storage.setItem(key, store)
}

const vuexLocal = new VuexPersistence({
  storage: {
    getItem: () =>
      getItem(sessionStorage, localEncryptionToken, sessionStorageKey),
    setItem: (key, value) =>
      setItem(sessionStorage, localEncryptionToken, sessionStorageKey, value),
    removeItem: () => sessionStorage.removeItem(sessionStorageKey)
  },
  modules: ['account', 'inplay', 'alert', 'misc'],
  reducer: (state) => {
    return {
      account: { lastLogin: state.account.lastLogin },
      inplay: {
        leaguesExpanded: state.inplay.leaguesExpanded,
        readInplayNotifications: state.inplay.readInplayNotifications
      },
      alert: { leagueFilters: state.alert.leagueFilters },
      misc: { timeZoneOptions: state.misc.timeZoneOptions }
    }
  }
})

const vuexSession = new VuexPersistence({
  storage: {
    getItem: () =>
      getItem(localStorage, sessionEncryptionToken, localStorageKey),
    setItem: (key, value) =>
      setItem(localStorage, sessionEncryptionToken, localStorageKey, value),
    removeItem: () => localStorage.removeItem(localStorageKey)
  },
  modules: ['inplay'],
  reducer: (state) => {
    return {
      enquirySent: state.enquirySent,
      inplay: {
        favourites: state.inplay.favourites,
        oddsExpanded: state.inplay.oddsExpanded,
        filters: state.inplay.filters
      }
    }
  }
})

export default createStore({
  state,
  getters: {},
  mutations,
  actions,
  modules: {
    account,
    alert,
    inplay,
    misc
  },
  plugins: [vuexLocal.plugin, vuexSession.plugin]
})
