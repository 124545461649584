import initialState from './state'

export const RESET = (state) => {
  const s = initialState()
  Object.keys(s).forEach((key) => {
    if (key !== 'lastLogin') state[key] = s[key]
  })
}

export const SET_ACCOUNT = (state, payload) => {
  state.premium = payload.premium
  state.user.email = payload.email
  state.user.firstName = payload.firstName
  state.user.lastName = payload.lastName
  state.user.dateOfBirth = payload.dateOfBirth
  state.user.timeZone = payload.timeZone
  state.user.telegramChatId = payload.telegramChatId
  state.plan.nextBillingDate = payload.nextBillingDate
  state.plan.nextBillingAmount = payload.nextBillingAmount
  state.plan.currentPlan = payload.currentPlan
  state.plan.paymentType = payload.paymentType
  state.notificationSettings.app = payload.appNotifications
  state.notificationSettings.email = payload.emailNotifications
  state.notificationSettings.telegram = payload.telegramNotifications
}

export const SET_LAST_LOGIN = (state, payload) => {
  state.lastLogin = payload.lastLogin
}

export const SET_EMAIL = (state, payload) => (state.user.email = payload.email)

export const SET_TIMEZONE = (state, payload) =>
  (state.user.timeZone = payload.timeZone)

export const SET_TELEGRAM_CHAT_ID = (state, payload) =>
  (state.user.telegramChatId = payload.telegramChatId)

export const SET_TELEGRAM_LINK = (state, payload) =>
  (state.telegramLink = payload.telegramLink)

export const SET_STRIPE_PORTAL = (state, payload) =>
  (state.plan.stripePortal = payload.stripePortal)

export const SET_APP_NOTIFICATIONS = (state, payload) =>
  (state.notificationSettings.app = payload.appNotifications)

export const SET_EMAIL_NOTIFICATIONS = (state, payload) =>
  (state.notificationSettings.email = payload.emailNotifications)

export const SET_TELEGRAM_NOTIFICATIONS = (state, payload) =>
  (state.notificationSettings.telegram = payload.telegramNotifications)
