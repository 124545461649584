import initialState from './state'

export const RESET = (state) => {
  const s = initialState()
  Object.keys(s).forEach((key) => (state[key] = s[key]))
}

export const SET_TIMEZONE_OPTIONS = (state, payload) => {
  state.timeZoneOptions = payload.timeZoneOptions
}
