import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/styles/fonts.css'
import './assets/styles/app.css'

import BaseButton from '@/components/BaseButton.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'
import BaseTextbox from '@/components/BaseTextbox.vue'
import BaseToggle from '@vueform/toggle'

const app = createApp(App)

app.use(store)
app.use(router)

app.component('BaseButton', BaseButton)
app.component('BaseDropdown', BaseDropdown)
app.component('BaseTextbox', BaseTextbox)
app.component('BaseToggle', BaseToggle)

app.mount('#app')
