import AxiosAuthenticated from '@/shared/AxiosAuthenticated'

export const getInplayGames = ({ commit }) => {
  return AxiosAuthenticated.get('livegames')
    .then((response) =>
      commit('SET_INPLAY_GAMES', { inplayGames: response.data })
    )
    .catch(() => Promise.resolve())
}

export const getPreviousResults = ({ commit }) => {
  return AxiosAuthenticated.get('livegames/previous-results')
    .then((response) =>
      commit('SET_PREVIOUS_RESULTS', { previousResults: response.data })
    )
    .catch(() => Promise.resolve())
}

export const expandLeague = ({ commit }, payload) => {
  commit('EXPAND_LEAGUE', { leagueId: payload.leagueId })
}

export const collapseLeague = ({ commit }, payload) => {
  commit('COLLAPSE_LEAGUE', { leagueId: payload.leagueId })
}

export const faveFixture = ({ commit }, payload) => {
  commit('ADD_FAVOURITE', { fixtureId: payload.fixtureId })
}

export const unFaveFixture = ({ commit }, payload) => {
  commit('REMOVE_FAVOURITE', { fixtureId: payload.fixtureId })
}

export const expandOdds = ({ commit }, payload) => {
  commit('EXPAND_ODDS', { fixtureId: payload.fixtureId })
}

export const collapseOdds = ({ commit }, payload) => {
  commit('COLLAPSE_ODDS', { fixtureId: payload.fixtureId })
}

export const collapseAllLeagues = ({ commit }) => commit('COLLAPSE_ALL_LEAGUES')

export const expandAllLeagues = ({ commit }) => commit('EXPAND_ALL_LEAGUES')

export const setSearchFilter = ({ commit }, payload) =>
  commit('SET_SEARCH_FILTER', { search: payload.search })

export const allGamesFilter = ({ state, commit }) => {
  if (state.filters.allGames) return
  commit('SET_FILTERS', { filter: 'allGames' })
}

export const firstHalfFilter = ({ state, commit }) => {
  if (state.filters.firstHalf) return
  commit('SET_FILTERS', { filter: 'firstHalf' })
}

export const secondHalfFilter = ({ state, commit }) => {
  if (state.filters.secondHalf) return
  commit('SET_FILTERS', { filter: 'secondHalf' })
}

export const halfTimeFilter = ({ state, commit }) => {
  if (state.filters.halfTime) return
  commit('SET_FILTERS', { filter: 'halfTime' })
}

export const drawScoreFilter = ({ state, commit }) => {
  if (state.filters.drawScore) return
  commit('SET_FILTERS', { filter: 'drawScore' })
}

export const underdogWinningFilter = ({ state, commit }) => {
  if (state.filters.underdogWinning) return
  commit('SET_FILTERS', { filter: 'underdogWinning' })
}

export const favouriteWinningFilter = ({ state, commit }) => {
  if (state.filters.favouriteWinning) return
  commit('SET_FILTERS', { filter: 'favouriteWinning' })
}

export const myFavouritesFilter = ({ state, commit }) => {
  if (state.filters.myFavourites) return
  commit('SET_FILTERS', { filter: 'myFavourites' })
}

export const getInplayAlerts = ({ commit }) => {
  return AxiosAuthenticated.get('inplayalerts')
    .then((response) =>
      commit('SET_NOTIFICATIONS', { notifications: response.data })
    )
    .catch(() => {})
}

export const removeAllNotifications = ({ commit }) => {
  return AxiosAuthenticated.post('inplayalerts/mark-alert-read-all')
    .then(() => commit('REMOVE_ALL_NOTIFICATIONS'))
    .catch(() => {})
}

export const removeSingleNotification = ({ commit }, payload) => {
  return AxiosAuthenticated.post('inplayalerts/mark-alert-read', {
    id: payload.alertId
  })
    .then((response) =>
      commit('REMOVE_SINGLE_NOTIFICATION', { id: response.data.id })
    )
    .catch(() => {})
}

export const updateReadNotifications = ({ commit }) =>
  commit('UPDATE_READ_NOTIFICATIONS')
