import AxiosInterceptor from '@/shared/AxiosInterceptor'
import AxiosAuthenticated from '@/shared/AxiosAuthenticated'

export const checkAlive = ({ commit }) => {
  return AxiosInterceptor.get('status')
    .then((response) => commit('SET_ALIVE', { alive: response.status === 200 }))
    .catch(() => commit('SET_ALIVE', { alive: false }))
}

export const authenticate = ({ commit }, payload) => {
  const header = { withCredentials: true }
  const request = { email: payload.email, password: payload.password }

  return AxiosInterceptor.post('authorisation', request, header)
    .then((response) => {
      if (response.data.awaitingValidation) return
      commit('SET_IDENTITY', response.data.token)
      return true
    })
    .catch(() => {
      return Promise.reject(
        'Your password is incorrect or this account does not exist.'
      )
    })
}

export const identify = ({ commit, dispatch }) => {
  return AxiosInterceptor.post('authorisation/refresh', null, {
    withCredentials: true
  })
    .then((response) =>
      commit('SET_IDENTITY', { identity: response.data.token })
    )
    .catch((error) => {
      dispatch('reset')
      return Promise.reject(error)
    })
}

export const revokeIdentity = () => {
  return AxiosAuthenticated.post('authorisation/revoke')
}

export const sendEnquiry = ({ commit }, payload) => {
  return AxiosAuthenticated.post('account/account-contact-request', {
    name: payload.name,
    email: payload.email,
    message: payload.message
  })
    .then(() => {
      commit('ENQUIRY_SENT')
      Promise.resolve()
    })
    .catch(() => Promise.reject())
}

export const reset = ({ commit, dispatch }) => {
  return new Promise((resolve) => {
    commit('misc/RESET', null, { root: true })
    commit('account/RESET', null, { root: true })
    commit('RESET')

    dispatch('checkAlive')
    resolve()
  })
}
