import AxiosInterceptor from '@/shared/AxiosInterceptor'
import AxiosAuthenticated from '@/shared/AxiosAuthenticated'

export const getAccountInfo = ({ commit }) => {
  return AxiosAuthenticated.get('account')
    .then((response) => {
      const data = response.data
      commit('SET_ACCOUNT', {
        premium: data.premium,
        email: data.user.email,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        dateOfBirth: data.user.dateOfBirth,
        timeZone: data.user.timeZone,
        telegramChatId: data.user.telegramChatID,
        nextBillingDate: data.plan.nextBillingDate,
        nextBillingAmount: data.plan.nextBillingAmount,
        currentPlan: data.plan.currentPlan,
        paymentType: data.plan.paymentType,
        appNotifications: data.notificationSettings.app,
        emailNotifications: data.notificationSettings.email,
        telegramNotifications: data.notificationSettings.telegram
      })
      return Promise.resolve()
    })
    .catch((error) => Promise.reject(error))
}

export const forgottenPassword = (_, payload) => {
  return AxiosInterceptor.post('account/forgotten-password', {
    email: payload.email
  })
    .then(() => Promise.resolve())
    .catch(() => Promise.resolve())
}

export const registerAccount = (_, payload) => {
  var dateParts = payload.dateOfBirth.split('-')
  var dateFormat = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T12:00:00.000Z`
  return AxiosInterceptor.post('account', {
    firstName: payload.firstName,
    lastName: payload.lastName,
    email: payload.email,
    password: payload.password,
    dateOfBirth: dateFormat
  })
    .then(() => Promise.resolve())
    .catch((error) => {
      const errorCode = error.response ? error.response.status : null
      let message =
        'Sorry, there was a problem with this request. Please retry at a later time.'

      if (errorCode === 409)
        message =
          'An account with this email address has already been registered.'
      else if (errorCode === 401) message = 'You must be 18+ to sign up.'

      return Promise.reject(message)
    })
}

export const verifyEmail = (_, payload) => {
  return AxiosInterceptor.post('account/verification', {
    verificationEmail: payload.verificationEmail,
    verificationToken: payload.verificationToken
  })
    .then(() =>
      Promise.resolve(
        'Your email has been verified and your account is now available to sign in.'
      )
    )
    .catch((error) => {
      const errorCode = error.response ? error.response.status : null
      let message =
        'Unable to verify account. Account is already verified or does not exist.'
      if (errorCode === 401)
        message =
          'Verification token has expired. A new verification email has been sent to your registered email address.'

      return Promise.reject(message)
    })
}

export const resetPassword = (_, payload) => {
  return AxiosInterceptor.post('account/reset-password', {
    resetToken: payload.resetToken,
    password: payload.password
  })
    .then(() => Promise.resolve('Your password has been reset.'))
    .catch((error) => {
      const errorCode = error.response ? error.response.status : null
      let message =
        'Unable to reset password, forgotten password link is invalid.'
      if (errorCode === 401)
        message =
          'Forgotten password link has expired. A new email has been sent to your registered email address.'

      return Promise.reject(message)
    })
}

export const resetVerification = (_, payload) => {
  return AxiosInterceptor.post('account/reset-verification/', {
    email: payload
  })
}

export const updateEmail = ({ commit, state }, payload) => {
  return AxiosAuthenticated.post('account/change-info', {
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    dateOfBirth: state.user.dateOfBirth,
    newEmail: payload.newEmail,
    currentPassword: payload.currentPassword
  })
    .then((response) => {
      if (response.data.emailUpdated)
        commit('SET_EMAIL', { email: response.data.email })
      else return Promise.reject()
    })
    .catch(() => Promise.reject())
}

export const updatePassword = (_, payload) => {
  return AxiosAuthenticated.post('account/change-password', {
    currentPassword: payload.currentPassword,
    newPassword: payload.newPassword
  })
}

export const updateTimeZone = ({ commit }, payload) => {
  return AxiosAuthenticated.post('account/update-timezone', {
    id: payload.timeZone
  })
    .then((response) =>
      commit('SET_TIMEZONE', { timeZone: response.data.timeZoneId })
    )
    .catch(() => Promise.reject())
}

export const linkTelegram = ({ commit }) => {
  return AxiosAuthenticated.post('account/link-telegram')
    .then((response) => {
      commit('SET_TELEGRAM_LINK', { telegramLink: response.data.telegramLink })
      commit('SET_TELEGRAM_CHAT_ID', {
        telegramChatId: response.data.telegramChatId
      })
    })
    .catch(() => Promise.reject())
}

export const stripePortal = ({ commit }) => {
  return AxiosAuthenticated.post('paymentwebhook/stripe-customer-portal')
    .then((response) =>
      commit('SET_STRIPE_PORTAL', {
        stripePortal: response.data.customerPortal
      })
    )
    .catch(() => Promise.reject())
}

export const toggleAppNotifications = ({ commit }) => {
  return AxiosAuthenticated.post('customalerts/toggle-app-notifications').then(
    (response) =>
      commit('SET_APP_NOTIFICATIONS', {
        appNotifications: response.data.appNotifications
      }).catch(() => Promise.reject())
  )
}

export const toggleEmailNotifications = ({ commit }) => {
  return AxiosAuthenticated.post(
    'customalerts/toggle-email-notifications'
  ).then((response) =>
    commit('SET_EMAIL_NOTIFICATIONS', {
      emailNotifications: response.data.emailNotifications
    }).catch(() => Promise.reject())
  )
}

export const toggleTelegramNotifications = ({ commit }) => {
  return AxiosAuthenticated.post(
    'customalerts/toggle-telegram-notifications'
  ).then((response) =>
    commit('SET_TELEGRAM_NOTIFICATIONS', {
      telegramNotifications: response.data.telegramNotifications
    }).catch(() => Promise.reject())
  )
}

export const setLastLogin = ({ commit }, payload) => {
  commit('SET_LAST_LOGIN', { lastLogin: payload.lastLogin })
}
