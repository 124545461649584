const initialState = () => {
  return {
    user: {
      email: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      timeZone: '',
      telegramChatId: 0
    },
    plan: {
      nextBillingDate: '',
      nextBillingAmount: 0.0,
      currentPlan: '',
      paymentType: '',
      stripePortal: ''
    },
    lastLogin: null,
    premium: false,
    telegramLink: '',
    oddsDbDetails: {
      username: '',
      password: '',
      url: ''
    },
    notificationSettings: {
      app: true,
      email: false,
      telegram: false
    }
  }
}

export default initialState
