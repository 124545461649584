import AxiosAuthenticated from '@/shared/AxiosAuthenticated'
import generateUUID from '@/shared/GuidGenerator.js'

export const createCustomAlert = ({ state, commit, dispatch }, payload) => {
  var requestBody = {
    id: generateUUID(),
    name: payload.name,
    groupOperator: state.customAlertOptions.operators.andAll,
    active: false
  }

  return AxiosAuthenticated.post('customalerts', requestBody)
    .then((response) => {
      commit('PUSH_CUSTOM_ALERT', { alert: response.data })
      return response.data.id
    })
    .catch((error) => {
      let message =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Unable to create an alert, please try again later'
      return Promise.reject(message)
    })
}

export const updateCustomAlert = ({ state, commit }, payload) => {
  return AxiosAuthenticated.post('customalerts/update-custom-alert', {
    id: payload.id,
    name: payload.name,
    groupOperator: payload.groupOperator
  })
    .then((response) =>
      commit('UPDATE_CUSTOM_ALERT', {
        id: response.data.id,
        name: response.data.name,
        groupOperator: response.data.groupOperator
      })
    )
    .catch(() => {})
}

export const getcustomAlerts = ({ commit, dispatch }) => {
  return AxiosAuthenticated.get('customalerts')
    .then((response) => {
      const data = response.data
      commit('SET_CUSTOM_ALERTS', {
        alerts: data.customAlerts,
        filters: data.customFilters,
        filterGroups: data.customGroups
      })
      return Promise.resolve()
    })
    .catch(() => Promise.reject())
}

export const removeCustomAlert = ({ commit }, payload) => {
  return AxiosAuthenticated.post('customalerts/remove-custom-alert', {
    id: payload.alertId
  })
    .then((response) =>
      commit('REMOVE_CUSTOM_ALERT', { alertId: response.data.id })
    )
    .catch(() => Promise.reject())
}

export const toggleActiveAlert = ({ commit }, payload) => {
  return AxiosAuthenticated.post('customalerts/toggle-custom-alert', {
    id: payload.alertId
  })
    .then((response) => {
      commit('SET_ALERT_ACTIVE_STATUS', {
        alertId: response.data.id,
        active: response.data.active
      })
      return response.data.active
    })
    .catch((error) => Promise.reject(error))
}

export const createCondition = ({ commit }, payload) => {
  let id = generateUUID()
  let numericValue = parseFloat(payload.numericValue)
  let timeValue = parseFloat(payload.timeValue)

  return AxiosAuthenticated.post('customalerts/create-custom-filter', {
    id: id,
    parentGroupId: payload.parentGroupId,
    category: payload.category,
    condition: payload.condition,
    filterOperator: payload.operator,
    numericValue: numericValue,
    timeFrame: payload.timeFrame,
    timeValue: timeValue
  })
    .then((response) => commit('PUSH_FILTER', { filter: response.data }))
    .catch(() => Promise.reject())
}

export const removeCondition = ({ commit }, payload) => {
  return AxiosAuthenticated.post('customalerts/remove-custom-filter', {
    id: payload.conditionId
  })
    .then((response) => commit('REMOVE_FILTER', { id: response.data.id }))
    .catch(() => Promise.reject())
}

export const createGroup = ({ commit }, payload) => {
  let id = generateUUID()
  let sumValue = parseFloat(payload.sumValue)

  return AxiosAuthenticated.post('customalerts/create-filter-group', {
    id: id,
    name: payload.name,
    groupOperator: payload.groupOperator,
    parentGroupId: payload.parentGroupId,
    alertId: payload.alertId,
    sumOperator: payload.sumOperator,
    sumValue: sumValue
  })
    .then((response) => {
      const data = response.data
      commit('PUSH_CUSTOM_ALERT_GROUP', {
        id: data.id,
        alertId: data.alertId,
        parentGroupId: data.parentGroupId,
        name: data.name,
        groupOperator: data.groupOperator,
        sumOperator: data.sumOperator,
        sumValue: data.sumValue
      })
      return Promise.resolve()
    })
    .catch((error) => {
      let message =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Unable to create filter group, please try again later'
      Promise.reject(message)
    })
}

export const removeGroup = ({ commit }, payload) => {
  return AxiosAuthenticated.post('customalerts/remove-filter-group', {
    id: payload.id
  })
    .then((response) =>
      commit('REMOVE_CUSTOM_ALERT_GROUP', { id: response.data.id })
    )
    .catch(() => Promise.reject())
}

export const updateGroup = ({ commit }, payload) => {
  let sumValue = parseFloat(payload.sumValue)
  return AxiosAuthenticated.post('customalerts/update-filter-group', {
    id: payload.id,
    name: payload.name,
    groupOperator: payload.groupOperator,
    sumOperator: payload.sumOperator,
    sumValue: sumValue
  }).then((response) =>
    commit('UPDATE_CUSTOM_ALERT_GROUP', {
      id: response.data.id,
      name: response.data.name,
      groupOperator: response.data.groupOperator,
      sumOperator: response.data.sumOperator,
      sumValue: response.data.sumValue
    }).catch(() => Promise.reject())
  )
}

export const togglePanel = ({ commit }) => {
  commit('TOGGLE_ALERT_PANEL')
}

export const updateAlertRegionSelections = ({ commit }, payload) => {
  return AxiosAuthenticated.post('customalerts/update-tree-for-query', {
    queryGuidId: payload.id,
    regionTree: payload.checkedRegions
  })
    .then((response) => {
      commit('UPDATE_ALERT_REGION_TREE', {
        alertId: response.data.queryGuidId,
        checkedRegions: response.data.regionTree
      })
    })
    .catch(() => {})
}

export const getLeagueAlertFilters = ({ commit }) => {
  return AxiosAuthenticated.get('customalerts/get-region-tree-full')
    .then((response) =>
      commit('SET_ALERT_LEAGUE_FILTERS', { leagueFilters: response.data })
    )
    .catch(() => {})
}

export const resetRegionTreeToDefault = ({ commit }, payload) => {
  return AxiosAuthenticated.post('customalerts/set-default-region-tree', {
    id: payload.alertId
  })
    .then((response) =>
      commit('UPDATE_ALERT_REGION_TREE', {
        alertId: response.data.queryGuidId,
        checkedRegions: response.data.regionTree
      })
    )
    .catch(() => {})
}
