<template>
  <div class="absolute h-full w-full flex flex-col justify-center items-center">
    <div v-if="!showFail" class="text-silver text-center">
      <img
        class="w-48"
        src="@/assets/images/animations/loading-dark-mode.gif"
      />
      <span class="text-2xl">Checking VAR</span>
    </div>

    <!-- MESSAGE BOX -->
    <div
      v-show="showFail"
      class="max-w-md w-full mx-auto px-4 space-y-12 text-center"
    >
      <div class="text-silver">
        <h2 class="text-xl mb-2">Service Down</h2>
        <p>
          We are experiencing an issue, and working tirelessly to resolve this
          as soon as possible.
        </p>
        <p>Thank you.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  setup() {
    const showFail = ref(false)

    setTimeout(() => {
      showFail.value = true
    }, 4000)

    return {
      showFail
    }
  }
}
</script>
