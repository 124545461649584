const initialState = () => {
  return {
    notifications: [],
    previousResults: {},
    inplayGames: [],
    inplayNotifications: [],
    readInplayNotifications: [],
    leaguesExpanded: [],
    oddsExpanded: [],
    favourites: [],
    filters: {
      search: '',
      allGames: true,
      firstHalf: false,
      secondHalf: false,
      halfTime: false,
      drawScore: false,
      underdogWinning: false,
      favouriteWinning: false,
      myFavourites: false
    }
  }
}

export default initialState
