<template>
  <nav class="w-full fixed z-50">
    <div
      class="relative w-full max-w-screen-2xl mx-auto flex items-center h-10"
      :class="{
        'bg-black-100 justify-between': inplayPage,
        'justify-end': !inplayPage
      }"
    >
      <div v-if="inplayPage" class="shrink scrolling-wrapper h-7">
        <div class="flex items-center justify-center w-10 h-7 pl-4 shrink-0">
          <img
            src="@/assets/images/icons/chevron.png"
            class="rotate-90 w-5 h-5 cursor-pointer"
            @click="expandLeagues"
          />
        </div>
        <div class="flex items-center justify-center w-10 h-7 shrink-0">
          <img
            src="@/assets/images/icons/chevron.png"
            class="-rotate-90 w-5 h-5 cursor-pointer"
            @click="collapseLeagues"
          />
        </div>
        <!-- <span class="px-4">{{liveMatchesCount}}</span> -->
        <input
          v-model="filterSearch"
          placeholder="search teams..."
          maxlength="18"
          class="h-6 mx-2 bg-transparent border-b border-silver border-opacity-50 outline-none text-sm font-light text-chalk"
        />
        <button
          @click="filterAllGames"
          class="scrolling-card"
          :class="{ active: filters.allGames }"
        >
          All Games
        </button>
        <button
          @click="filterMyFavourites"
          class="scrolling-card"
          :class="{ active: filters.myFavourites }"
        >
          My Favourites
        </button>
        <button
          @click="filterFirstHalf"
          class="scrolling-card"
          :class="{ active: filters.firstHalf }"
        >
          First Half
        </button>
        <button
          @click="filterSecondHalf"
          class="scrolling-card"
          :class="{ active: filters.secondHalf }"
        >
          Second Half
        </button>
        <button
          @click="filterHalfTime"
          class="scrolling-card"
          :class="{ active: filters.halfTime }"
        >
          Half Time
        </button>
        <button
          @click="filterDrawScore"
          class="scrolling-card"
          :class="{ active: filters.drawScore }"
        >
          Draw Score
        </button>
        <button
          @click="filterUnderdogWinning"
          class="scrolling-card"
          :class="{ active: filters.underdogWinning }"
        >
          Underdog Winning
        </button>
        <button
          @click="filterFavouriteWinning"
          :class="{ active: filters.favouriteWinning }"
        >
          Favourite Winning
        </button>
      </div>

      <div class="flex space-x-2 items-center ml-4 pr-2 pt-1 shrink-0">
        <div class="w-5">
          <img
            v-show="newNotifications"
            class="cursor-pointer"
            :src="require('@/assets/images/animations/notification.gif')"
            @click="toggleViewNotifications"
          />
          <img
            v-show="newNotifications == false && allNotificationIds.length > 0"
            class="cursor-pointer"
            :src="require('@/assets/images/icons/alerts.png')"
            @click="toggleViewNotifications"
          />
        </div>
        <div
          class="w-8 cursor-pointer"
          @click="mobileMenuOpen = !mobileMenuOpen"
        >
          <img src="@/assets/images/menu.png" alt="Menu" />
        </div>
      </div>
    </div>
  </nav>

  <div v-if="mobileMenuOpen" class="absolute z-50 h-full w-full bg-black">
    <div class="h-full w-full max-w-screen-2xl mx-auto flex flex-col">
      <span id="version">{{ `v. ${versionNumber}` }}</span>
      <div class="h-10 flex justify-end items-center px-2">
        <div
          v-if="false"
          class="w-8 cursor-pointer"
          @click="mobileMenuOpen = !mobileMenuOpen"
        >
          <img src="@/assets/images/icons/closer.png" alt="Close" />
        </div>
      </div>
      <div class="w-full h-full flex flex-col items-center justify-center">
        <div
          v-for="(route, index) in routes"
          :key="route"
          class="text-xl flex flex-col items-center"
        >
          <div
            @click="go(route)"
            class="h-20 flex items-center hover:text-2xl hover:text-green cursor-pointer"
            :class="{ 'text-green opacity-90': currentPage === route }"
          >
            <div>
              {{ route }}
            </div>
          </div>
          <div
            v-if="index != routes.length - 1"
            class="border-b border-silver w-4 opacity-70"
          ></div>
        </div>
        <div
          @click="logout"
          class="text-xl mt-24 h-20 flex items-center hover:text-2xl hover:text-green cursor-pointer"
        >
          <div>Log out</div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="notificationsViewOpen"
    class="absolute z-50 h-full w-full bg-black"
  >
    <div
      class="h-full pb-20 px-4 w-full max-w-screen-2xl mx-auto flex flex-col"
    >
      <div class="relative">
        <h3 class="text-center mt-6 mb-10">Alert Notifications</h3>
        <div
          class="absolute -right-2 top-5 flex flex-col-reverse justify-center items-center"
        >
          <img
            @click="clearAll"
            class="w-6 cursor-pointer mt-4"
            src="@/assets/images/icons/clear-all.png"
            alt="Clear notifications"
          />
          <img
            @click="toggleViewNotifications"
            class="w-8 cursor-pointer"
            src="@/assets/images/icons/closer.png"
            alt="Close"
          />
        </div>
      </div>

      <div class="h-full w-full overflow-hidden overflow-y-auto">
        <div
          v-for="notification in allNotifications"
          :key="notification.id"
          class="text-center mb-8 text-sm"
        >
          <div
            class="bold"
            :class="{
              'text-green': !readNotifications.includes(notification.id)
            }"
          >
            {{ fromDescription(notification) }}
          </div>
          <div class="opacity-70">
            {{ leagueDescription(notification) }}
          </div>
          <div class="opacity-70">
            {{ fixtureDescription(notification) }}
          </div>
          <div class="opacity-70">{{ statusDescription(notification) }}</div>
          <div
            class="cursor-pointer hover:underline"
            @click="removeSingleNotification(notification.id)"
          >
            Clear
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'TheNavBar',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const versionNumber = ref(process.env.VUE_APP_VERSION)

    const mobileMenuOpen = ref(false)
    const notificationsViewOpen = ref(false)

    const currentPage = computed(() => route.name)
    const currentIcon = computed(() => route.meta.icon)
    const filters = computed(() => store.state.inplay.filters)

    const filterSearch = computed({
      get() {
        return filters.value.search
      },
      set(value) {
        store.dispatch('inplay/setSearchFilter', { search: value })
      }
    })

    const inplayPage = computed(() => currentPage.value === 'In Play')

    const routes = computed(() => {
      return router.options.routes
        .filter((x) => x.meta.requiresAuth)
        .map((x) => x.name)
    })

    const logout = () => {
      store
        .dispatch('revokeIdentity')
        .then(() => router.push({ name: 'Guest' }))
    }

    const go = async (route) => {
      await router.push({ name: route })
      mobileMenuOpen.value = false
    }

    const allNotifications = computed(
      () => store.state.inplay.inplayNotifications
    )

    const allNotificationIds = computed(() =>
      allNotifications.value.map((x) => x.id)
    )

    const readNotifications = computed(
      () => store.state.inplay.readInplayNotifications
    )

    const newNotifications = computed(
      () =>
        allNotificationIds.value.filter(
          (x) => readNotifications.value.includes(x) == false
        ).length > 0
    )

    const expandLeagues = () => store.dispatch('inplay/expandAllLeagues')

    const collapseLeagues = () => store.dispatch('inplay/collapseAllLeagues')

    const filterAllGames = () => store.dispatch('inplay/allGamesFilter')
    const filterMyFavourites = () => store.dispatch('inplay/myFavouritesFilter')
    const filterFirstHalf = () => store.dispatch('inplay/firstHalfFilter')
    const filterSecondHalf = () => store.dispatch('inplay/secondHalfFilter')
    const filterHalfTime = () => store.dispatch('inplay/halfTimeFilter')
    const filterDrawScore = () => store.dispatch('inplay/drawScoreFilter')
    const filterUnderdogWinning = () =>
      store.dispatch('inplay/underdogWinningFilter')
    const filterFavouriteWinning = () =>
      store.dispatch('inplay/favouriteWinningFilter')

    const toggleViewNotifications = () => {
      if (notificationsViewOpen.value) markAsRead()
      notificationsViewOpen.value = !notificationsViewOpen.value
    }

    const alertTimeDescription = (date) => {
      var now = new Date()
      var triggerTime = new Date(date)

      var seconds = Math.floor((now - triggerTime) / 1000)
      var minutes = Math.floor(seconds / 60)
      var hours = Math.floor(minutes / 60)
      var days = Math.floor(hours / 24)

      hours = hours - days * 24
      minutes = minutes - days * 24 * 60 - hours * 60
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

      if (hours > 0) {
        return `${hours} hour ago`
      } else if (minutes > 0) {
        return `${minutes} minute ago`
      } else {
        return 'now'
      }
    }

    const fixtureDescription = (notification) =>
      `${notification.homeTeam} ${notification.homeScore} - ${notification.awayScore} ${notification.awayTeam}`

    const statusDescription = (notification) => {
      if (notification.status === 'HT' || notification.status === 'FT')
        return `${notification.status} (at time of alert)`

      let minutesPlayed =
        notification.extraTime > 0
          ? `${notification.minutesPlayed}' + ${notification.extraTime}`
          : `${notification.minutesPlayed}'`
      return `${notification.status} ${minutesPlayed} (at time of alert)`
    }

    const leagueDescription = (notification) =>
      `${notification.country}, ${notification.league}`

    const fromDescription = (notification) =>
      `${alertTimeDescription(notification.triggerTime)} - ${
        notification.alertName
      }`

    const removeSingleNotification = (notificationId) => {
      store.dispatch('inplay/removeSingleNotification', {
        alertId: notificationId
      })
    }

    const markAsRead = () => store.dispatch('inplay/updateReadNotifications')
    const clearAll = () => store.dispatch('inplay/removeAllNotifications')

    return {
      routes,
      currentPage,
      currentIcon,
      mobileMenuOpen,
      notificationsViewOpen,
      filters,
      filterSearch,
      inplayPage,
      newNotifications,
      allNotificationIds,
      allNotifications,
      readNotifications,
      versionNumber,
      go,
      logout,
      collapseLeagues,
      expandLeagues,
      filterAllGames,
      filterMyFavourites,
      filterFirstHalf,
      filterSecondHalf,
      filterHalfTime,
      filterDrawScore,
      filterUnderdogWinning,
      filterFavouriteWinning,
      toggleViewNotifications,
      fixtureDescription,
      statusDescription,
      leagueDescription,
      fromDescription,
      alertTimeDescription,
      removeSingleNotification,
      clearAll
    }
  }
}
</script>

<style scoped lang="scss">
button {
  color: white;
  @apply bg-black-200;

  border-radius: 5px;
  padding: 1px 6px;
  margin-left: 10px;

  font-size: 0.85em;

  &.active {
    @apply text-green;
  }

  flex-shrink: 0;
}

#version {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
}
</style>
