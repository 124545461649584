import axios from 'axios'

const axiosInterceptor = axios.create({})
const join = require('url-join')

axiosInterceptor.interceptors.request.use((config) => {
  var isAbsoluteURLRegex = /^(?:\w+:)\/\//

  if (!isAbsoluteURLRegex.test(config.url))
    config.url = join(process.env.VUE_APP_API_BASE, config.url)

  return config
})

axiosInterceptor.interceptors.response.use(
  (response) => {
    if (response) return response
    return Promise.reject()
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInterceptor
