import initialState from './state'
import * as actions from './actions'
import * as mutations from './mutations'

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations,
  actions
}
